import regular from "./regular.js"
/****修改密码模块********************************************************************************************************/

/**
 * 原密码判空验证
 * 原密码输入长度验证
 * 原密码非法字符验证
 * @param rule
 * @param value
 * @param callback
 */
let validateOldPassWord = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入原密码'));
    }
    if (!regular.regAZaz09.test(value)) {
        callback(new Error('原密码只能输入英文和数字'));
    }
    if (!regular.regLength620.test(value)) {
        callback(new Error('长度在 6 到 20 个字符'));
    }
    callback()
};
/**
 * 新密码判空验证
 * 新密码非法字符验证
 * 新密码长度验证
 * @param rule
 * @param value
 * @param callback
 */
let validatePassWord = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入密码'));
    }
    if (!regular.regAZaz09.test(value)) {
        callback(new Error('新密码只能输入英文和数字'));
    }
    if (!regular.regLength620.test(value)) {
        callback(new Error('长度在 6 到 20 个字符'));
    }
    callback()
};
/**
 * 确认密码判空验证
 * 确认密码非法字符验证
 * 确认密码长度验证
 * @param rule
 * @param value
 * @param callback
 */
let validateConfirmPassword = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入确认密码'));
    }
    if (!regular.regAZaz09.test(value)) {
        callback(new Error('确认密码只能输入英文和数字'));
    }
    if (!regular.regLength620.test(value)) {
        callback(new Error('长度在 6 到 20 个字符'));
    }
    callback()
};

/****客户管理管理模块******************************************************************************************************************/

/**
 * 客户全称
 * @param rule
 * @param value
 * @param callback
 */
let validateSysCiFullName = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入全称'));
    }
    if (!regular.regLength20.test(value)) {
        callback(new Error('长度在 1 到 20 个字符'));
    }
    callback()
};

/**
 * 客户简称
 * @param rule
 * @param value
 * @param callback
 */
let validateSysCiShortName = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入简称'));
    }
    if (!regular.regLength20.test(value)) {
        callback(new Error('长度在 1 到 20 个字符'));
    }
    callback()
};

/**
 * 客户地址
 * @param rule
 * @param value
 * @param callback
 */
let validateSysCiAddress = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入地址'));
    }
    if (!regular.regLength50.test(value)) {
        callback(new Error('长度在 1 到 50 个字符'));
    }
    callback()
};

/**
 * 客户联系人
 * @param rule
 * @param value
 * @param callback
 */
let validateSysCiContacts = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入联系人'));
    }
    if (!regular.regLength20.test(value)) {
        callback(new Error('长度在 1 到 20 个字符'));
    }
    callback()
};

/**
 * 客户联系电话
 * @param rule
 * @param value
 * @param callback
 */
let validateSysCiTel = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入联系电话'));
    }
    if (!regular.regPhone.test(value)) {
        callback(new Error('请输入正确手机号'));
    }
    callback()
};

/****角色管理管理模块******************************************************************************************************************/

/**
 * 角色名称
 * @param rule
 * @param value
 * @param callback
 */
let validateSysRiName = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入联系人'));
    }
    if (!regular.regLength20.test(value)) {
        callback(new Error('长度在 1 到 20 个字符'));
    }
    callback()
};
/****人员管理管理模块******************************************************************************************************************/

/**
 * 名称
 * @param rule
 * @param value
 * @param callback
 */
let personnelName = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入姓名'));
    }
    if (!regular.regLength20.test(value)) {
        callback(new Error('长度在 1 到 20 个字符'));
    }
    callback()
};
/**
 * 手机号
 * @param rule
 * @param value
 * @param callback
 */
let personnelTel = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入联系电话'));
    }
    if (!regular.regPhone.test(value)) {
        callback(new Error('联系电话输入错误'));
    }
    callback()
};
/**
 * 球镜验证
 * @param rule
 * @param value
 * @param callback
 */
let goodsIciSphTest = (rule, value, callback) => {
    if (!regular.regSph.test(value)) {
        callback(new Error('球镜输入错误'));
    }
    callback()
};
/**
 * 柱镜验证
 * @param rule
 * @param value
 * @param callback
 */
let goodsIciCylTest = (rule, value, callback) => {
    if (!regular.regSph.test(value)) {
        callback(new Error('柱镜输入错误'));
    }
    callback()
};
/****短信平台模块******************************************************************************************************************/

/**
 * 账户名称
 * @param rule
 * @param value
 * @param callback
 */
let messageName = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入账户名称'));
    }
    if (!regular.regLength20.test(value)) {
        callback(new Error('长度在 1 到 20 个字符'));
    }
    callback()
};
/**
 * 账户密码
 * @param rule
 * @param value
 * @param callback
 */
let messagePassWord = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入账户名称'));
    }
    if (!regular.regLength20.test(value)) {
        callback(new Error('长度在 1 到 20 个字符'));
    }
    callback()
};
/******************下拉参数维护模块**********************************************************************************/
/**
 * 下拉值长度验证
 * @param rule
 * @param value
 * @param callback
 */
let messageGoodsPdValue = (rule,value,callback) => {
    if (value === ''){
        callback(new Error('请输入下拉值内容'));
    }
    if (!regular.regLength20.test(value)) {
        callback(new Error('长度在 1 到 20 个字符'));
    }
    callback()
};
/**
 * 下拉值名称长度验证
 * @param rule
 * @param value
 * @param callback
 */
let messageGoodsPdName = (rule,value,callback) => {
    if (value === ''){
        callback(new Error('请输入下拉值名称'));
    }
    if (!regular.regLength20.test(value)) {
        callback(new Error('长度在 1 到 20 个字符'));
    }
    callback()
};
export default {
    //修改密码
    validateConfirmPassword,
    validatePassWord,
    validateOldPassWord,
    //客户管理
    validateSysCiFullName,
    validateSysCiShortName,
    validateSysCiAddress,
    validateSysCiContacts,
    validateSysCiTel,

    //角色管理
    validateSysRiName,
    //人员管理
    personnelName,
    personnelTel,
    //隐形商品维护
    goodsIciSphTest,
    goodsIciCylTest,
    // 短信平台
    messageName,
    messagePassWord,
    //下拉参数维护
    messageGoodsPdValue,
    messageGoodsPdName,
}