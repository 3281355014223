<template>
   <div id="app">
      <router-view></router-view>
   </div>
</template>

<script>
export default {
   name: 'App',
   watch: {
      '$store.state.globalTheme'() {
         document.body.setAttribute('data-theme', this.$store.state.globalTheme)
      }
   },
   created() {
      window.location.hash = '/'
      let userInfo = sessionStorage.getItem('userInfo')
      this.$store.state.userInfo = JSON.parse(userInfo)
      let permissionIds = sessionStorage.getItem('permissionIds')
      this.$store.state.permissionIds = JSON.parse(permissionIds)
      // let dataTheme = sessionStorage.getItem('globalTheme');
      // document.body.setAttribute('data-theme', dataTheme ? dataTheme : 'default')
      // let container = parseInt(sessionStorage.getItem('container'))
      // this.$store.state.container = container ? container : 1
      // this.$store.state.globalTheme = dataTheme ? dataTheme : 'default'
      // this.$common.initParameterAssignment()
   },
}
</script>

<style lang="scss">
@import "~@/assets/css/themeify.scss";
@import "~@/assets/icon/iconfont.css";
@import "~@/assets/css/common.scss";

#app {
   height: 100%;
}

body {
   margin: 0;
   height: 100%;
   overflow: hidden;
   .el-scrollbar__wrap {
      overflow-x: hidden;
   }
}
.el-table--medium .el-table__cell {
   padding: 6px 0 !important;
}
.el-table--small .el-table__cell {
   padding: 4px 0 !important;
}
.el-table--mini .el-table__cell {
   padding: 2px !important;
}
/*滚动条样式*/
::-webkit-scrollbar {
   /*滚动条整体样式*/
   width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
   height: 6px;
}

::-webkit-scrollbar-thumb {
   /*滚动条里面小方块*/
   border-radius: 5px;
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
   background: #90939980;
}

::-webkit-scrollbar-track {
   /*滚动条里面轨道*/
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
   border-radius: 0;
   background: #fff;
}
//禁止选中
.noSelect {
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
.nowrap {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}
//解决表格复选框对不齐问题
.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
   padding-left: 10px !important;
   padding-right: 10px !important;
}
.el-table thead {
   height: 39px !important;
}
</style>
