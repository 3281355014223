<template>
  <div>
    <el-table
      ref="psTable"
      :data="data"
      border
      v-bind="tableBind"
      v-on="$listeners"
    >
      <slot slot="tool" />
      <template v-for="tp in typesColumns">
        <el-table-column
          v-if="tp.type === 'expand'"
          :key="tp.type"
          v-bind="tp.props"
          type="expand"
        >
          <template slot-scope="props">
            <slot name="expand" v-bind="props" />
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="tp.type"
          :type="tp.type"
          :reserve-selection="reserveSelection && tp.type === 'selection'"
          v-bind="tp.props"
        />
      </template>
      <el-table-column
        v-for="col in renderColumns"
        :key="col.label"
        v-bind="getColBind(col)"
      >
        <template slot-scope="scope">
          <slot
            v-if="col.custom"
            :name="col.label"
            :row="scope.row"
            :column="scope.column"
            :$index="scope.$index"
          ></slot>
          <component
            :is="col.component"
            v-else
            v-bind="getCptBind(scope, col)"
            v-on="col.listeners"
          />
        </template>
      </el-table-column>

      <template v-if="slotAppend" slot="append">
        <slot name="append" />
      </template>
    </el-table>

    <!-- <pagination
      v-if="total"
      :currentPage.sync="page_num"
      :pageSize.sync="page_size"
      :total="total"
      v-bind="paginationProps"
      @current-change="pagination"
      @size-change="pagination"
    /> -->
  </div>
</template>

<script>
import ElTable from 'element-ui/lib/table'
import ElTableColumn from 'element-ui/lib/table-column'
import methods from './methods'
import Text from './text'

const BOOLEAN_KEYS = [
  'fit',
  'stripe',
  'border',
  'show-header',
  'highlight-current-row',
  'default-expand-all',
  'show-summary',
]

const COLUMN_PROPS = {
  align: 'left',
  component: Text,
}

const TYPES = ['selection', 'expand', 'index']

const COLUMN_KEY_MAP = {
  label: 'label',
  prop: 'prop',
}

export default {
  name: 'psTable',

  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },

  mixins: [methods],

  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },

    columns: {
      type: Array,
      default() {
        return []
      },
    },

    columnType: [String, Array],

    columnTypeProps: Object,

    columnKeyMap: Object,

    columnsProps: Object,

    columnsSchema: Object,

    columnsHandler: Function,

    slotAppend: Boolean,

    reserveSelection: Boolean,
    total: {
      type: [Number, String],
      required: false,
    },
    paginationProps: {
      type: Object,
      default() {
        return {
          autoScroll: true,
        }
      },
    },
  },

  data() {
    return {
      flag: 10,
      page_size: 10,
      page_num: 1,
    }
  },

  computed: {
    ElTable() {
      return this.$refs.ElTable
    },
    tableBind() {
      const { $attrs } = this
      const bind = {}
      Object.keys($attrs).forEach((key) => {
        const v = $attrs[key]
        const uniformKey = key.replace(/([A-Z])/, '-$1').toLowerCase()
        bind[key] = ~BOOLEAN_KEYS.indexOf(uniformKey) && v === '' ? true : v
      })
      return bind
    },

    renderColumns() {
      const {
        columns,
        columnKeyMap,
        columnsHandler,
        columnsProps: props,
        columnsSchema: schema,
      } = this
      const map = Object.assign({}, COLUMN_KEY_MAP, columnKeyMap)
      const renderColumns = columns.map((col) => {
        const mix = (schema && schema[col[map.label]]) || {}
        const it = Object.assign({}, COLUMN_PROPS, props, col, mix)
        it.label = it[map.label]
        it.prop = it[map.prop]
        return it
      })
      return (columnsHandler && columnsHandler(renderColumns)) || renderColumns
    },
    typesColumns() {
      const { columnType: type, columnTypeProps } = this
      let typeColums = []
      if (typeof type === 'string' && ~TYPES.indexOf(type)) {
        typeColums = [type]
      }
      if (Array.isArray(type)) {
        typeColums = type.filter((it) => ~TYPES.indexOf(it))
      }
      const map = columnTypeProps || {}
      return typeColums.map((type) => {
        return {
          type,
          props: map[type],
        }
      })
    },
  },
  methods: {
    pagination() {
      const page = this.flag
      if (page !== this.page_size) {
        this.page_num = 1
      }
      this.$emit('pagination', {
        page_num: this.page_num,
        page_size: this.page_size,
      })
      this.flag = this.page_size
    },

    _setPageNum(page_num = 1) {
      this.page_num = page_num
    },

    _setPageSize(page_size = 10) {
      this.page_size = page_size
    },

    getColBind(col) {
      const bind = Object.assign({}, col)
      delete bind.component
      delete bind.listeners
      delete bind.propsHandler
      return bind
    },

    getCptBind({ row, column, $index }, col) {
      const index = $index
      const props = { row, col, column, index }
      const handler = col.propsHandler
      return (handler && handler(props)) || props
    },
  },
}
</script>
