import ElementUI from 'element-ui'
import router from "../../router";
import axios from "axios";

// let loading;
const $axios = axios.create({
    timeout: 100000,
})


//http request 拦截器
$axios.interceptors.request.use(
    config => {
        // config.data = JSON.stringify(config.data);
        config.headers = {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('Authorization') === null ? '' : sessionStorage.getItem('Authorization')
        }
        if (!sessionStorage) {
            ElementUI.Notification({
                title: '错误',
                message: '您的浏览器版本太低,请升级浏览器',
                type: 'error'
            })
        }
        return config;
    },
    err => {
        ElementUI.Notification({
            title: '错误',
            message: '请求失败:' + Promise.reject(err),
            type: 'error'
        })
        return Promise.reject(err);
    }
);
$axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        let errorStr = error + '';
        let title = '';
        let message = '';
        let type = '';
        if (errorStr.indexOf("401") !== -1) {
            router.push({
                path: '/login'
            })
            sessionStorage.removeItem('Authorization')
            return Promise.reject(error);
        }
        if (errorStr.indexOf("403") !== -1) {
            title = '警告';
            message = '权限不足';
            type = 'warning';
        }
        if (errorStr.indexOf("500") !== -1) {
            title = '错误';
            message = '程序异常，请联系管理员';
            type = 'error';
        }
        if (errorStr.indexOf("404") !== -1) {
            title = '错误';
            message = '找不到了';
            type = 'error';
        }
        ElementUI.Notification({
            title: title,
            message: message || '请求超时',
            type: type
        })
        return Promise.reject(error);
    },
)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        $axios.get(url, {
                params: params,
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        $axios.post(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function remove(url, params = {}) {
    return new Promise((resolve, reject) => {
        $axios.delete(url, {
                params: params,
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}


export function exportFile(url, params = {}) {
    return axios({
        method: 'post',
        url: url,
        responseType: 'blob',
        data: JSON.stringify(params),
        headers: {
            Authorization: sessionStorage.getItem('Authorization') === null ? '' : sessionStorage.getItem('Authorization')
        }
    }).then(res => {
        let fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
        }));
        link.target = "_blank";
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}