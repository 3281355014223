<template>
  <div>
    <div class="box">
      <el-card class="box-card" shadow="never" :body-style="{ padding: '10px' }">
        <div class="flex_box" ref="searchBar">
          <div class="row" ref="row_content" :style="style">
            <slot name="content"></slot>
          </div>
          <div class="btn_box">
            <el-button
              class="mt5"
              :size="$store.state.size"
              type="text"
              style="text-align: right"
              @click="handleButton"
              :icon="!close ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
              v-show="height > 80 ? true : false"
              >{{ !close ? "展开" : "收起" }}
            </el-button>
          </div>
        </div>
        <div class="light">
          <div class="line mb10"></div>
          <slot name="footer"></slot>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "psSearchBar",

  props: {
    // row 代表一行摆列几个栏
    row: {
      type: Number,
      default: 4,
    },
  },

  data() {
    return {
      close: true,
      height: 80,
      style: {
        height: "auto",
      },
    }
  },

  mounted() {
    this.$nextTick(() => {
      const h = this.$refs.row_content.offsetHeight
      this.height = h
      this.handleButton()
    })
    this.$refs.searchBar.style.setProperty("--w", 100 / this.row + "%")
  },

  methods: {
    handleButton() {
      this.close = !this.close
      if (this.close) {
        this.style.height = "auto"
      }
      this.$emit('clickIcon')
    },
  },
}
</script>

<style lang="scss" scoped>
.row {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.line {
  border-top: 1px solid #ebeef5;
}

.flex_box {
  display: flex;
}

.btn_box {
  width: 60px;
}

.light {
  z-index: 3;
}
</style>

<style>
.row .bar_item {
  box-sizing: border-box;
  height: 60px;
  padding: 5px;
  width: var(--w);
  display: flex;
  align-items: center;
}

.row .bar_item .label {
  text-align: right;
  padding-right: 10px;
}
</style>
