export default {
  functional: true,
  props: ['row', 'col', 'column'],
  render(
    h, {
      props: {
        row,
        col
      },
      _v: text
    }
  ) {
    const {
      formatter
    } = col

    function canShowText(val) {
      if (typeof val !== 'undefined' && val !== null) return val
      return ''
    }
    let val = formatter && formatter(row, col)
    if (val === 0) {
      val = '0'
    }
    const v = val || canShowText(row[col.prop], col.prop)
    return (text && text(v)) || v
  }
}
