<template>
  <div class="c">
    <i class="el-icon-loading r"></i> 加载中...
  </div>
</template>

<script>
export default {
  name: "inputLoading",

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped>
.r {
  color: #0078d7;
  font-size: 12px;
}

.c {
  display: flex;
  align-items: center;
  height: 20px;
  background: red;
  overflow: hidden;
  // transform: scale(0.92);
  // transform: translateY(-50%);
}
</style>
