/**
 * 通用长度限制
 * @type {RegExp}
 */
let regLength20 = new RegExp('^.{1,20}$');
/**
 * 通用长度限制
 * @type {RegExp}
 */
let regLength50 = new RegExp('^.{1,50}$');

/**
 * 手机号验证
 * @type {RegExp}
 */
let regPhone = new RegExp(`^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\\d{8}$`);




/**
 * 大小写英文字母和数字
 * @type {RegExp}
 */
let regAZaz09 = new RegExp('^[A-Za-z0-9]+$');

/**
 * 字符串长度在6~8位
 * @type {RegExp}
 */
let regLength68 = new RegExp('^.{6,8}$');
/**
 * 字符串长度在6~8位
 * @type {RegExp}
 */
let regLength620 = new RegExp('^.{6,20}$');
/**
 * 去空格
 * @type {RegExp}
 */
let regRemoveSpace = new RegExp('/\\s+/g');

/**
 * 中文、英文、数字但不包括下划线等符号
 * @type {RegExp}
 */
let regChAZaz09 = new RegExp('^[\u4E00-\u9FA5A-Za-z0-9]+$');

/**
 * 中文、英文但不包括下划线等符号
 * @type {RegExp}
 */
let regChAZaz = new RegExp('^[\u4E00-\u9FA5A-Za-z]+$');

/**
 * 字符串长度在2~8位
 * @type {RegExp}
 */
let regLength28 = new RegExp('^.{2,8}$');

/**
 * 邮箱验证
 * @type {RegExp}
 */
let regEmail= new RegExp('^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$');
/**
 * 球镜柱镜验证
 * @type {RegExp}
 */
let  regSph = new RegExp('^$|^[\\+\\-]?[0-9]{1,2}([.]{1}[0-9]{1,2})?$');
/**
 * 轴向验证
 * @type {RegExp}
 */
let regAxial = new RegExp('^(\\+?)(([1]?[0-9][0-9]?)|180)$');

/**
 *
 */
let reg09 = new RegExp(`^[0-9]*$`)

export default {
    regLength20,
    regLength620,
    regLength50,
    regSph,
    regAxial,
    regAZaz09,
    regLength68,
    regRemoveSpace,
    regChAZaz09,
    regLength28,
    regEmail,
    regPhone,
    regChAZaz,
    reg09
}
