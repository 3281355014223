import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [{
            path: '/index',
            component: () => import('@/page/index'),
            redirect: '/console',
            children: [{
                    path: '/console',
                    name: 'console',
                    component: () => import('@/page/console'),
                    meta: {
                        path: '/console',
                        index: '1',
                        title: '主控台',
                    }
                },
                {
                    path: '/customer',
                    name: 'customer',
                    component: () => import('@/page/system/customer'),
                    meta: {
                        path: '/customer',
                        index: '2-1',
                        title: '客户管理',
                    }
                },
                {
                    path: '/role',
                    name: 'role',
                    component: () => import('@/page/system/role'),
                    meta: {
                        path: '/role',
                        index: '2-2',
                        title: '角色管理',
                    }
                },
                {
                    path: '/applet',
                    name: 'applet',
                    component: () => import('@/page/system/applet'),
                    meta: {
                        path: '/applet',
                        index: '2-3',
                        title: '小程序配置',
                    }
                },
                {
                    path: '/shortMessage',
                    name: 'shortMessage',
                    component: () => import('@/page/system/shortMessage'),
                    meta: {
                        path: '/shortMessage',
                        index: '2-4',
                        title: '短信平台管理',
                    }
                },
                {
                    path: '/personnel',
                    name: 'personnel',
                    component: () => import('@/page/system/personnel'),
                    meta: {
                        path: '/personnel',
                        index: '2-5',
                        title: '人员管理',
                    }
                },
                {
                    path: '/log',
                    name: 'log',
                    component: () => import('@/page/system/log'),
                    meta: {
                        path: '/log',
                        index: '2-6',
                        title: '日志管理',
                    }
                },
                {
                    path: '/interface',
                    name: 'interface',
                    component: () => import('@/page/system/interface'),
                    meta: {
                        path: '/interface',
                        index: '2-7',
                        title: '接口维护',
                    }
                },
                {
                    path: '/systemParameter',
                    name: 'systemParameter',
                    component: () => import('@/page/system/systemParameter'),
                    meta: {
                        path: '/systemParameter',
                        index: '2-8',
                        title: '系统参数',
                    }
                },
                {
                    path: '/dropDownData',
                    name: 'dropDownData',
                    component: () => import('@/page/system/dropDownData'),
                    meta: {
                        path: '/dropDownData',
                        index: '2-9',
                        title: '数据字典',
                    }
                },

                {
                    path: '/productName',
                    name: 'productName',
                    component: () => import('@/page/commodity/productName'),
                    meta: {
                        path: '/productName',
                        index: '3-1',
                        title: '品种维护',
                    }
                },

                // {
                //     path: '/productName',
                //     name: 'productName',
                //     component: () => import('@/page/commodity/productName/index2.vue'),
                //     meta: {
                //         path: '/productName',
                //         index: '3-1',
                //         title: '品种维护',
                //     }
                // },
                {
                    path: '/contactLens',
                    name: 'contactLens',
                    component: () => import('@/page/commodity/contactLens'),
                    meta: {
                        path: '/contactLens',
                        index: '3-2',
                        title: '商品维护',
                    }
                },
                {
                    path: '/brandName',
                    name: 'brandName',
                    component: () => import('@/page/commodity/brandName'),
                    meta: {
                        path: '/brandName',
                        index: '3-3',
                        title: '品牌维护',
                    }
                },
                {
                    path: '/order',
                    name: 'order',
                    component: () => import('@/page/order/order'),
                    meta: {
                        path: '/order',
                        index: '4-1',
                        title: '订单管理',
                    }
                },
                {
                    path: '/basicProcessing',
                    name: 'basicProcessing',
                    component: () => import('@/page/machining/basicProcessing'),
                    meta: {
                        path: '/basicProcessing',
                        index: '5-1',
                        title: '基础加工参数维护',
                    }
                },
                {
                    path: '/earlyWarning',
                    name: 'earlyWarning',
                    component: () => import('@/page/wechat/earlyWarning'),
                    meta: {
                        path: '/earlyWarning',
                        index: '6-1',
                        title: '预警管理',
                    }
                },
                {
                    path: '/orderReminder',
                    name: 'orderReminder',
                    component: () => import('@/page/wechat/orderReminder'),
                    meta: {
                        path: '/orderReminder',
                        index: '6-2',
                        title: '订单提醒',
                    }
                },
                {
                    path: '/manufacturerAdvertising',
                    name: 'manufacturerAdvertising',
                    component: () => import('@/page/wechat/manufacturerAdvertising'),
                    meta: {
                        path: '/manufacturerAdvertising',
                        index: '6-3',
                        title: '厂家广告',
                    }
                },
                {
                    path: '/inventoryView',
                    name: 'inventoryView',
                    component: () => import('@/page/inventory/inventoryView'),
                    meta: {
                        path: '/inventoryView',
                        index: '7-1',
                        title: '库存查看',
                    }
                },
                {
                    path: '/email',
                    name: 'email',
                    component: () => import('@/page/email/email/index.vue'),
                    meta: {
                        path: '/email',
                        index: '8-1',
                        title: '邮件解析规则',
                    }
                },
                // {
                //     path: '/emailGoods',
                //     name: 'emailGoods',
                //     component: () => import('@/page/email/emailGoods/index.vue'),
                //     meta: {
                //         path: '/emailGoods',
                //         index: '8-3',
                //         title: '邮件解析商品',
                //     }
                // },
                {
                    path: '/emailLogs',
                    name: 'emailLogs',
                    component: () => import('@/page/email/emailLogs/index.vue'),
                    meta: {
                        path: '/emailLogs',
                        index: '8-2',
                        title: '邮件解析日志',
                    }
                },

            ]
        },
        {
            path: '/',
            name: '',
            component: () => import('@/page/login'),
        }, {
            path: '/login',
            name: 'login',
            component: () => import('@/page/login'),
        }
    ]

})

router.beforeEach((to, from, next) => {
    if (to.path !== '/login' && !sessionStorage.Authorization) {
        next('/login')
    } else {
        next()
    }
    if (sessionStorage.Authorization && (to.path == '/login' || to.path == '/')) {
        next('/console')
    }
})

export default router