import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.css'
import VueAxiosPlugin from 'vue-axios-plugin';
import Server from './providers/result'
import common from './assets/js/common'
import regular from './assets/js/regular'
import * as result from './providers/http-server'
import * as echarts from 'echarts';
import verification from './assets/js/verification'
import '@/styles/util.sass'
import 'components/global'
import VueCompositionAPI from '@vue/composition-api'
import inputLoading from '@/directive/inputLoading'
import max32 from '@/directive/max32'
Vue.use(ElementUI, {
  size: 'small'
});
Vue.use(VueCompositionAPI)
Vue.prototype.$server = Server
Vue.prototype.$common = common;
Vue.prototype.$regular = regular;
Vue.prototype.$echarts = echarts;
Vue.prototype.$verification = verification;
Vue.use(VueAxiosPlugin, result)
Vue.config.productionTip = false
Vue.directive('i', inputLoading)
Vue.directive('max32', max32);
document.addEventListener('input', function (e) {
  if (e.target.value.length > 100) {
    e.target.value = e.target.value.slice(0, 100); //01234
  } else {
    if (e.target.type === 'text' && e.target.getAttribute('maxlength') === null) {
      e.target.setAttribute('maxlength', '100') // 限制最长输入50个字符
    }
    // input框 type='textarea'，且本身没有设置maxlength长度
    if (e.target.type === 'textarea' && e.target.getAttribute('maxlength') === null) {
      e.target.setAttribute('maxlength', '100') // 限制最长输入500个字符
    }
  }


})
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
/**
 * 页面跳转滚动回到顶部
 */
// router.afterEach(() => {
//   document.querySelector('.el-card').scrollTo(0,0)
// })