<template>
  <div class="c"></div>
</template>

<script>
export default {
  name: "box",

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style scoped>
.c {
  background: red;
  height: 10px;
}
</style>
