// eslint-disable-next-line no-unused-vars
import { exportFile, get, post, put, remove } from '../http-server'
import da from "element-ui/src/locale/lang/da"

export default {
    /**********************************通用下载文件接口*******************************/
    download: function (params = {}) {
        return exportFile('/api/file/download', params)
    },
    /**********************************login模块*******************************/
    /**
     * 登录接口
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    login: function (data = {}) {
        return post('/api/login', data)
    },

    /**
     * 登出接口
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    logout: function (data = {}) {
        return post('/api/logout', data)
    },
    /**
     * 修改密码
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    changePassword: function (data = {}) {
        return post('/api/system/sys-user-info/updatPassword', data)
    },
    /**********************************客户管理模块*******************************/

    /**
     * 客户信息分页查询
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCustomerInfo: function (param = {}) {
        return get('/api/system/sys-customer-info/getByPage', param)
    },

    /**
     * 客户列表
     * @param param
     * @returns {Promise}
     */
    getCustomerList: function (param = {}) {
        return get('/api/system/sys-customer-info/getList', param)
    },

    /**
     * 客户类型
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCustomerType: function (param = {}) {
        return get('/api/system/sys-customer-info/findCustomerType', param)
    },

    /**
     * 保存客户信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveCustomerInfo: function (data = {}) {
        return post('/api/system/sys-customer-info/saveOrUpdate', data)
    },
    /**
     * 获取制造商编码
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCustomerCode: function (data = {}) {
        return get('/api/system/sys-customer-info/getCustomerCode', data)
    },

    /**
     * 删除客户信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    removeCustomerInfo: function (param = {}) {
        return remove('/api/system/sys-customer-info/delete', param)
    },

    /**********************************角色管理模块*******************************/

    /**
     * 角色信息分页查询
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getRoleInfo: function (param = {}) {
        return get('/api/system/sys-role-info/getByPage', param)
    },

    /**
     * 保存角色信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveRoleInfo: function (data = {}) {
        return post('/api/system/sys-role-info/saveOrUpdate', data)
    },
    /**
     * 角色启用禁用
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateStatus: function (data = {}) {
        return post('/api/system/sys-role-info/updateStatus', data)
    },
    /**
     * 删除客户信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    removeRoleInfo: function (param = {}) {
        return remove('/api/system/sys-role-info/delete', param)
    },

    /**
     * 权限树查询
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getGrantTree: function (param = {}) {
        return get('/api/system/sys-menu-info/getList', param)
    },


    /**
     * 权限树反选id集合
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getGrantIdList: function (param = {}) {
        return get('/api/system/sys-menu-info/getMenuIdList', param)
    },

    /**
     * 保存角色信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveGrant: function (data = {}) {
        return post('/api/system/sys-menu-role/save', data)
    },
    /**********************************人员信息模块*******************************/
    /**
     * 客户类型查询
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    findCustomerType: function (param = {}) {
        return get('/api/system/sys-customer-info/findCustomerType', param)
    },
    /**
     * 人员信息分页查询
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getByPage: function (param = {}) {
        return get('/api/system/sys-user-info/getByPage', param)
    },
    /**
     * 删除人员信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteponseInfo: function (param = {}) {
        return remove('/api/system/sys-user-info/delete', param)
    },
    /**
     * 保存人员信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveOrUpdate: function (data = {}) {
        return post('/api/system/sys-user-info/saveOrUpdate', data)
    },
    /**
     * 保存人员信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updatePasswordForce: function (data = {}) {
        return post('/api/system/sys-user-info/updatePasswordForce', data)
    },
    /**
     * 根据客户类型查询所属客户列表
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getList: function (data = {}) {
        return get('/api/system/sys-customer-info/getList', data)
    },
    /**
     * 根据客户类型查询所属客户列表
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    roleGetList: function (data = {}) {
        return get('/api/system/sys-role-info/getList', data)
    },
    /**
     * 删除客户信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    userDelete: function (param = {}) {
        return remove('/api/system/sys-user-info/delete', param)
    },
    /**********************************接口维护模块*******************************/

    /**
     * 接口维护分页查询
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getInterfaceInfo: function (param = {}) {
        return get('/api/system/sys-interface-info/getByPage', param)
    },

    /**
     * 接口类型
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    getInterfaceType: function (param = {}) {
        return get('/api/system/sys-interface-info/getInterfaceType', param)
    },
    /**
     * 保存接口信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveInterfaceInfo: function (data = {}) {
        return post('/api//system/sys-interface-info/saveOrUpdate', data)
    },
    /**
     * 删除接口信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    removeInterfaceInfo: function (param = {}) {
        return remove('/api/system/sys-interface-info/delete', param)
    },
    /**********************************品名维护管理模块*******************************/
    /**
     * 品名维护查询
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    productInfoGetByPage: function (param = {}) {
        return get('/api/goods/product-info/getByPage', param)
    },
    /**
     * 获取制造商编码
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getProductCode: function (data = {}) {
        return get('/api/goods/product-info/getProductCode', data)
    },
    /**
     * 品名维护保存
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    productsaveOrUpdate: function (param = {}) {
        return post('/api/goods/product-info/saveOrUpdate', param)
    },
    /**
     * 删除品名信息
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    productsaveDelete: function (param = {}) {
        return remove('/api/goods/product-info/delete', param)
    },
    /**
     * 根据品名名称模糊查询品名列表
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getGoodsPiNameList: function (param = {}) {
        return get('/api/goods/product-info/getList', param)
    },
    /**
     * 品名导入
     * @param param
     */
    productImport: function (param = {}) {
        return post('/api/goods/product-info/productImport', param)
    },
    /**********************************隐形商品模块*******************************/
    /**
     * 隐形商品查询
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    invisibleInfoGetByPage: function (param = {}) {
        return get('/api/goods/invisible-care-info/getByPage', param)
    },
    /**
     * 隐形商品保存
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    invisiblesaveOrUpdate: function (param = {}) {
        return post('/api/goods/invisible-care-info/saveOrUpdate', param)
    },
    /**
     * 获取品名列表
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getProductList: function (param = {}) {
        return get('/api/goods/product-info/getProductList', param)
    },
    /**
     * 获取品名
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    invisiblesaveDelete: function (param = {}) {
        return remove('/api/goods/invisible-care-info/delete', param)
    },
    /**********************************加工参数管理模块*******************************/

    /**
     * 加工参数分页查询
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getMachiningInfo: function (param = {}) {
        return get('/api/param/machining-info/getByPage', param)
    },
    /**
     * 新增下拉维护项
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveOrUpdate2: function (data = {}) {
        return post('/api/param/machining-droplist/saveOrUpdate2', data)
    },
    /**
     * 删除下拉维护项
     * @param data
     * @returns {Promise}
     */
    deleteDroplist: function (data = {}) {
        return remove('/api/param/machining-droplist/delete', data)
    },
    /**
     * 加工参数列表
     * @returns {Promise<AxiosResponse<any>>}
     */
    getMachiningList: function (data = {}) {
        return get('/api/param/machining-info/getList', data)
    },
    /**
     * 保存加工参数
     * @param data
     * @returns {Promise}
     */
    saveMachiningInfo: function (data = {}) {
        return post('/api/param/machining-info/saveOrUpdate', data)
    },
    /**
     * 查询加工参数下拉列表(分页)
     * @param data
     * @returns {Promise}
     */
    getMachiningGetByPage: function (data = {}) {
        return get('/api/param/machining-droplist/getByPage', data)
    },
    /**
     * 查询加工参数下拉列表
     * @param data
     * @returns {Promise}
     */
    getMachiningDroplist: function (data = {}) {
        return get('/api/param/machining-droplist/getList', data)
    },
    /**
     * 删除加工参数
     * @param data
     * @returns {Promise}
     */
    deleteMachining: function (data = {}) {
        return remove('/api/param/machining-info/delete', data)
    },
    /**
     * 保存参数下拉列表
     * @param data
     * @returns {Promise}
     */
    saveMachiningDroplist: function (data = {}) {
        return post('/api/param/machining-droplist/saveOrUpdate', data)
    },
    /**
     * 获取加工参数（膜层） 列表
     * @param data
     * @returns {Promise}
     */
    getJuseList: function (data = {}) {
        return get('/api/param/machining-droplist/getJuseList', data)
    },
    /**********************************系统参数模块*******************************/
    /**
     * 保存系统参数
     * @param data
     * @returns {Promise}
     */
    syssaveOrUpdate: function (data = {}) {
        return post('/api/system/sys-parameter-info/saveOrUpdate', data)
    },
    /**
     * 查询加工参数下拉列表
     * @param data
     * @returns {Promise}
     */
    parameterlist: function (data = {}) {
        return get('/api/system/sys-parameter-info/getInfo', data)
    },
    /**********************************订单管理模块*******************************/
    /**
     * 查询订单管理列表
     * @param data
     * @returns {Promise}
     */
    getordorderlist: function (data = {}) {
        return get('/api/order/ord-order-info/getByPage', data)
    },
    /**
     * 删除订单
     * @param data
     * @returns {Promise}
     */
    deleteordorder: function (data = {}) {
        return remove('/api/order/ord-order-info/delete', data)
    },
    /**
     * 订单状态下拉框列表
     * @param data
     * @returns {Promise}
     */
    findOrderStateOrder: function (data = {}) {
        return get('/api/order/ord-order-info/findOrderState', data)
    },
    /**********************************订单管理模块*******************************/
    /**
     * 下拉维护
     * @param data
     * @returns {Promise}
     */
    productDroplistByPage: function (data = {}) {
        return get('/api/goods/product-droplist/getByPage', data)
    },
    /**
     * 主值列表
     * @param data
     * @returns {Promise}
     */
    grincipalValueList: function (data = {}) {
        return get('/api/goods/product-droplist/getPrincipalValueList', data)
    },
    /**
    * 所属模块
    * @param data
    * @returns {Promise}
    */
    getModeList: function (data = {}) {
        return get('/api/system/sys-menu-info/getModeList', data)
    },
    /**
     * 所属模块列表
     * @param data
     * @returns {Promise}
     */
    productDroplistByGroup: function (data = {}) {
        return get('/api/goods/product-droplist/getByGroup', data)
    },
    /**
     * 新增下拉参数
     * @param data
     * @returns {Promise}
     */
    productDroplistUpdate: function (data = {}) {
        return post('/api/goods/product-droplist/saveOrUpdate', data)
    },
    /**
     * 删除
     * @param data
     * @returns {Promise}
     */
    productDroplistDelete: function (data = {}) {
        return remove('/api/goods/product-droplist/delete', data)
    },
    /**
     * 子值停启用状态
     * @param data
     * @returns {Promise}
     */
    updateStatusDroplist: function (data = {}) {
        return get('/api/goods/product-droplist/updateStatus', data)
    },
    /***************** 品牌模块 ****************************************/
    /**
     * 分页查询
     * @param data
     * @returns {Promise}
     */
    getBrandByPage: function (data = {}) {
        return get('/api/goods/brand-info/getByPage', data)
    },
    /**
     * 获取平拍编码
     * @param data
     * @returns {Promise}
     */
    getBrandCode: function (data = {}) {
        return get('/api/goods/brand-info/getBrandCode', data)
    },
    /**
     * 保存
     * @param data
     */
    brandSaveOrUpdate: function (data = {}) {
        return post('/api/goods/brand-info/saveOrUpdate', data)
    },
    /**
     * 删除
     * @param data
     * @returns {boolean}
     */
    brandDelete: function (data = {}) {
        return remove('/api/goods/brand-info/delete', data)
    },
    /**
     * 后去品牌列表
     * @param data
     * @returns {Promise}
     */
    getBrandList: function (data = {}) {
        return get('/api/goods/brand-info/getList', data)
    },
    /***************** 商品类别模块 ****************************************/
    getCategoryList: function (data = {}) {
        return get('/api/goods/product-category/getList', data)
    },
    /***************** 供应商-制造商模块 ****************************************/
    /**
     * 查询所属制造商列表
     * @param data
     * @returns {Promise}
     */
    supplierManufacturerList: function (data = {}) {
        return get('/api/system/sys-manufacturer-supplier/getManufacturerListBySupplier', data)
    },
    /**
     * 删除所属制造商
     * @param data
     * @returns {Promise}
     */
    delSupplierManufacturer: function (data = {}) {
        return remove('/api/system/sys-manufacturer-supplier/delete', data)
    },
    /**
     * 新增所属制造商
     * @param data
     * @returns {Promise}
     */
    addSupplierManufacturer: function (data = {}) {
        return post('/api/system/sys-manufacturer-supplier/addManufacturer', data)
    },

    //  yangfan
    /**
     * 商品管理模块
     */

    getProductTableData: function (data = {}) {
        return get('/api/goods/goods-info/getByPage', data)
    },

    deleteProductItem: function (data = {}) {
        return remove('/api/goods/goods-info/deleteGoodsInfo', data)
    },

    postProductData: function (data = {}) {
        return post('/api/goods/goods-info/addGoodsInfo', data)
    },

    addSkuList: function (data = {}) {
        return post('/api/goods/goods-info/addGoodsSku', data)
    },

    saveProductInfo: function (data = {}) {
        return post('/api/goods/goods-info/addGoodsInfo', data)
    },

    getOtherSKU: function (data = {}) {
        return get('/api/goods/goods-sku/getSkuListByGoodsInfoId', data)
    },

    // 订单相关模块
    // 首屏获取零售商列表
    getShopkeeperList: function (data = {}) { // 3代表零售商  sysCiType: 3
        return get('/api/system/sys-customer-info/getByPage', data)
    },

    uploadFile: function (data = {}) {
        return post('/api/email/role-info/getExcelHeadList', data)
    },

    saveRole: function (data = {}) {
        return post('/api/email/role-info/addEmailRole', data)
    },

    getAllRulesPage: function (data = {}) {
        return get('/api/email/role-info/getByPage', data)
    },

    getGHSList: function (data = { sysCiType: 2 }) { // 获取供应商下拉 // 3是零售商
        return get('/api/system/sys-customer-info/getList', data)
    },

    delRules: function (data = {}) {
        return remove('/api/email/role-info/deleteEmailRole', data)
    },

    analysisEmail: function (data = {}) {
        return get('/api/email/email/storeMail', data)
    },

    // ------------- 邮件解析日志模块 ------------------------
    getEmailLog: function (data = {}) {
        return get('/api/email/fail-log/getByPage', data)
    },

    // ------------- 批量添加SKU ------------------------
    addSKUBatch: function (data = {}) {
        return get('/api/goods/goods-sku/settingSkus', data)
    },

    // ------------- 邮件失败二次解析 ------------------------
    reSendEmail: function (data = {}) {
        return get('/api/email/email/storeFailMail', data)
    },

    getProductEmail: function (data = {}) {
        return get('/api/email/email-goods/getByPage', data)
    },

    // 品种维护
    getPName: function (data = {}) {
        return get('/api/goods/product-info/getByPage', data)
    },

    // 保存品种
    savePName: function (data = {}) {
        return post('/api/goods/product-info/saveOrUpdate', data)
    },

}
