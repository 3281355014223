import vuex from '@/vuex/index'
import de from "element-ui/src/locale/lang/de";

const themeList = ["default", "dark", "_blue"]
//初始化参数全局参数赋值
function initParameterAssignment() {
    let parameterAssignment = JSON.parse(sessionStorage.getItem('sysParameterInfo'))
    let userInfo = sessionStorage.getItem('userInfo')
    vuex.state.userInfo = JSON.parse(userInfo)
    let permissionIds = sessionStorage.getItem('permissionIds')
    vuex.state.permissionIds = JSON.parse(permissionIds)
    let dataTheme = sessionStorage.getItem('globalTheme');
    // document.body.setAttribute('data-theme', dataTheme ? dataTheme : 'default')
    vuex.state.container = 1 // 0：左右布局；1：上下布局；
    vuex.state.globalTheme = parameterAssignment.sysPiTheme ? themeList[parameterAssignment.sysPiTheme] : 'default'
    vuex.state.isHeader = parameterAssignment.sysPiHeadNavidation == 0 ? true : false //头部导航栏展开收起开关
    vuex.state.isCollapse = true //侧边栏展开收起开关
    vuex.state.fastNav = parameterAssignment.sysPiFastNavidation == 0 ? true : false //快速导航栏开关
    vuex.state.closeModel = false // 关闭弹框提示
    // vuex.state.closeModelWithShadow = parameterAssignment.sysPiModalClose == 0 ? true : false//点击模态框阴影关闭
    vuex.state.userVisible = false ///点击用户可见
    // vuex.state.initPageSize = parameterAssignment.sysPiRows ? parameterAssignment.sysPiRows : '10'//默认每页显示条数
    // vuex.state.theme = parameterAssignment.sysPiThemeColour != 'undefined' && parameterAssignment.sysPiThemeColour ? parameterAssignment.sysPiThemeColour : '#409EFF'
    // localStorage.setItem('colorPicker', vuex.state.theme)
}

function _saveTheme(target, value) {
    let sysParameterInfo = JSON.parse(sessionStorage.getItem('sysParameterInfo'))
    // sysParameterInfo[target] = value
    // sessionStorage.setItem('sysParameterInfo', JSON.stringify(sysParameterInfo))
}

//计算表格高度
function getHeight(offsetHeight) {
    //占位初始高度
    let height = 88;
    //展开头部占用高度
    if (vuex.state.isHeader === 0) {
        height += 60;
    }
    //快速导航占用高度
    if (vuex.state.fastNav === 0) {
        height += 31
    }
    return window.innerHeight - height - (offsetHeight ? offsetHeight : 0);
}

//表格行高转换
function tableLineHeight() {
    let item = vuex.state.size;
    if (item === '') {
        return 'medium';
    }
    if (item === 'medium') {
        return 'small';
    }
    return 'mini'
}

//判断当前用户是否有权限
function hasPermission(index) {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if (userInfo.roleName == '超级管理员') {
        return true
    }
    return vuex.state.permissionIds.includes(index)
}

//清空搜索框数据（此方法适用于模板搜索组件）
function getSearchItemObject(searchInfo, val) {
    for (const item of searchInfo.searchForm) {
        if (item.name === val) {
            return item;
        }
    }
}

//清空表单方法（此方法适用于模板搜索方法）
function clearSearchInfo(searchForm) {
    for (const argument of searchForm) {
        argument.value = ''
    }
}

//递归对象清空对象中的值
function clearFormValue(form) {
    Object.keys(form).forEach((key) => {
        if (form[key] instanceof Array) {
            for (let keyElement of form[key]) {
                if (keyElement.constructor === String) {
                    form[key] = []
                } else {
                    clearFormValue(keyElement)
                }
            }
        } else if (form[key] instanceof Object) {
            form[key] = {}
        } else {
            form[key] = ''
        }
    })
}

//下载文件（此方法不能进行权限管理已废弃）
function download(url) {
    const urlArr = url.split('/')
    const fileName = urlArr[urlArr.length - 1]
    window.location.href = '/api/file/download?fileName=' + fileName
    this.$server.download({
        fileName: fileName
    }).then(res => {
        console.log(res)
    })
}

//自动补零
function blur(e) {
    if (e.target.value.indexOf(".") != -1) {
        let str = e.target.value.split(".");
        if (str[0].length > 0) {
            let arr = str[0].split("");
            let res = arr.every((item, index) => {
                return item == 0 || item == "0";
            });
            if (res) {
                if (str[1]) {
                    e.target.value = "0." + str[1];
                } else {
                    if (str[1] == 0) {
                        e.target.value = "0.00";
                    } else {
                        e.target.value = "";
                    }
                }
            } else {
                if (str[1]) {
                    e.target.value = str[0].replace(/\b(0+)/g, "") + "." + str[1];
                } else {
                    e.target.value = str[0].replace(/\b(0+)/g, "") + ".00";
                }
            }
        }
        if (str[1].length == 1) {
            e.target.value = e.target.value + "0";
        }
    } else {
        let arr = e.target.value.split("");
        let res = arr.every((item, index) => {
            return item == 0 || item == "0";
        });
        if (res) {
            if (e.target.value && e.target.value == 0) {
                e.target.value = "0.00";
            } else {
                e.target.value = "";

            }
        } else {
            e.target.value = e.target.value.replace(/\b(0+)/g, "") + ".00";
        }
    }
    return e.target.value
}

export default {
    getHeight,
    tableLineHeight,
    hasPermission,
    getSearchItemObject,
    clearSearchInfo,
    clearFormValue,
    download,
    initParameterAssignment,
    blur,
    _saveTheme
}