import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        currentRouterState:1, // 1是点击左侧菜单栏  2是点击头部菜单栏
        routerList:[], // 缓存路由列表
        name: '供应商订单平台',
        permissionIds: [],
        userInfo: {},
        globalTheme: 'default', //全局主题
        theme: '#409EFF', // 主题颜色
        container: 1, // 1：左右布局；2：上下布局；
        isCollapse: true, // 侧边栏展开收起开关
        isHeader: true, // 头部导航栏展开收起开关
        drawer: false, // 右侧抽屉开关
        fastNav: true, // 快速导航栏开关
        defaultActive: '1-1', // 默认选中导航位置标识
        size: 'small', // 默认尺寸
        closeModel: false, // 关闭模态框提示
        closeModelWithShadow: false, // 点击模态框阴影关闭
        // userVisible:false,  //点击用户可见
        initPageSize:10,//
    },
    mutations: {}
})

export default store
